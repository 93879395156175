<template>
  <div>
    <b-row>
      <b-col cols="4">
        <h2>Conversations</h2>

        <b-card
          v-for="conversation in conversations"
          :key="conversation.id"
          @click="fetchMessages(conversation.id)"
          class="bg-info text-white fw-bold"
        >
          <b-card-title>
            {{ conversation.participants.data[0].name }}
          </b-card-title>
        </b-card>
      </b-col>
    
      <b-col cols="8">
        <template v-if="selectedConversationId">

          <h2>Messages</h2>
         
          <b-card>
            <b-spinner v-if="loading" class="d-flex justify-content-center" label="Loading..." />
            <ul
              v-for="chat in array"
              :key="chat.id"
              class="fw-bold text-decoration-none"
            >
          


          
              <p class="fw-bold">Message : {{ chat.message }}</p>

              <p class="fw-bold">From : {{ chat.from.name }}</p>

              <p class="fw-bold">Date : {{convertToLocalTime(chat.created_time) }}</p>
            </ul>
            <b-dropdown-divider />
          </b-card>
        </template>
      </b-col>
    </b-row>
    <!-- Display a list of the user's conversations -->

    <!-- Display a list of messages for the selected conversation if a conversation has been selected -->
  </div>
</template>

<script>
import axios from "axios";
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BBadge,
  BForm,
  BCardTitle,
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BDropdownDivider,
  BButton,
} from "bootstrap-vue";
export default {
  name: "Chat",

  components: {
    BCard,
    BSpinner,
    BBadge,
    BCardTitle,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BDropdownDivider,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
  },

  data() {
    return {
      conversations: [],
      localTime: "",
      array: [  ],
      messages: [],
      selectedConversationId: null,
      accessToken: localStorage.getItem("facebookToken"),
    };
  },

  methods: {
    
    convertToLocalTime(timestamp) {
      const date = new Date(timestamp);
      this.localTime = date.toLocaleString();
      return this.localTime;
    },
    // Fetch the messages for the selected conversation
    async fetchMessages(conversationId) {
       this.array = [];
       this.loading = true;
      // Use the access token to fetch the messages for the selected conversation
      const url = process.env.VUE_APP_API_URL
      await axios.get(`${url}marketing/facebook/messages?token=${this.accessToken}&thread_id=${conversationId}`,
      
      ).then((response) => {
        this.loading = false;
        this.messages = response.data.result.messages.data;
      });

      this.messages.forEach((message) => {
        this.array.push(message);
      });
     

      // Set the selected conversation ID
      this.selectedConversationId = conversationId;
    },
  },
  // Fetch the user's conversations when the component is mounted
  async mounted() {
    // Use the access token to fetch the user's conversations
    const conversationsResponse = await axios.get(
      "https://graph.facebook.com/me/conversations?fields=participants&limit=5",
      {
        params: {
          access_token: this.accessToken,
        },
      }
    );
    this.conversations = conversationsResponse.data.data;

  },

      
  
};
</script>
